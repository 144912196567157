/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getOrientation,
  resetOrientation,
  checkImage,
  fetchApi
} from "../../../utils/methods";
import { ImageUrl, TimeZone, imageErrorMessage } from "../../../utils/constants";
import { withTranslation } from "react-i18next";
import {
  RemoveImage,
  QueAndCard,
} from "./style";
import { Main } from "../../AdminTeamCreation/styles";
import StepsHeading from "./stepNames";
import Waiting from "../../Waiting";
import HoverButton from "../../common/HoverButton";
import {
  addQueIcon,
  likertScaleIcon,
  mcqIcon,
  opinionScaleIcon,
  reorderIcon,
  shortDescIcon,
  yesnoIcon,
  Arrows,
  LaunchEventIcon,
  NextArrows,
  UploadIcon,
  closeIcon,
  CalendarIcon,
  SearchButtonIcon
} from "../../../utils/icons";
import {
  GetSurveyCategories,
  GetSurveyQuestionTypes,
  GetSurveyLibraryDetails,
  CreateSurveyV2
} from "../../../redux/constants/apiConstants";
import CommonTextArea from "../../common/CommonTextArea/CommonTextArea";
import DropDown from "../../common/CommonDropDown/DropDown";
import CommonDropdown from "../../common/CommonDropDown/CommonDropdown";
import { Border } from "../../Journeys/styles";
import { ToggleButton } from "../../Recognition/AddCoreValues/styles";
import { withRouter } from "react-router-dom";
import CreateSurveyPopUp from "./CreateSurveyPopUp";
import RadioButton from "../../CustomRadioButton";
import moment from "moment";
import QuestionReorderPopUp from "./QuestionReorderPopUp";
import CommonButton from "../../common/CommonButton/CommonButton";
import {
  ChallengeHeading,
  ButtonContainer,
  Layout,
  Container,
  InputContainer,
  StyledLabel,
  StyledInputV2,
  UploadPhotoContainer,
  RemoveImageV2,
  InputImage,
  EditorContainer,
  Heading,
  Locationwrapper,
  CustomRadioButton,
  StyledLabelV2,
  MUICalendarWrapper,
} from '../../CreateEvent/style';
import Editor from "../../CKEditor";
import { getAllCompanies, getCompanyDepartment, getCompanyLocation} from "../../../redux/actions";
import { GetCompanyInfo, GetTeams } from "../../../redux/constants/apiConstants";
import { http } from "../../../redux/http";
import {
  getUserCompanyDetails,
  allowChallengeEventForSpouseDependent,
} from "../../../redux/actions/userProfileActions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { SearchFilter1, SearchIcon } from "../../PeopleHomeV2/styles";
import StepNames from "../../CreateEvent/stepNames";
import DynamicStyledTextArea from "./DynamicStyledTextArea";

const surveypoints = [
  { value: 0, text: "No Points" },
  { value: 25, text: "25 Points" },
  { value: 50, text: "50 Points" },
  { value: 100, text: "100 Points" },
  { value: 150, text: "150 Points" },
  { value: 200, text: "200 Points" },
  { value: 250, text: "250 Points" },
  { value: 300, text: "300 Points" },
  { value: 350, text: "350 Points" },
  { value: 400, text: "400 Points" },
  { value: 450, text: "450 Points" },
  { value: 500, text: "500 Points" },
  { value: 600, text: "600 Points" },
  { value: 650, text: "650 Points" },
  { value: 700, text: "700 Points" },
  { value: 750, text: "750 Points" },
  { value: 800, text: "800 Points" },
  { value: 850, text: "850 Points" },
  { value: 900, text: "900 Points" },
  { value: 950, text: "950 Points" },
  { value: 1000, text: "1000 Points" },
];
const opinionpoints = [
  { value: 0, text: "0pt" },
  { value: 10, text: "10pts" },
];

const likeartpoints = [
  { value: 1, text: "1pt" },
  { value: 5, text: "5pts" },
];
// const surveypoints =[{value:5, text:"5 Points"}, {value:10, text:"10 Points"},{value:15, text:"15 Points"}];

const likertScaleValues = [
  { answer: "Strongly Agree", points: 5,label: "Strongly Agree" },
  { answer: "Agree", points: 4,label: "Agree" },
  { answer: "Neutral", points: 3,label: "Neutral" },
  { answer: "Disagree", points: 2,label: "Disagree" },
  { answer: "Strongly Disagree", points: 1,label: "Strongly Disagree" },
];

const opinionScaleValues = [
  { answer: "", points: 0 },
  { answer: "", points: 1 },
  { answer: "", points: 2 },
  { answer: "", points: 3 },
  { answer: "", points: 4 },
  { answer: "", points: 5 },
  { answer: "", points: 6 },
  { answer: "", points: 7 },
  { answer: "", points: 8 },
  { answer: "", points: 9 },
  { answer: "", points: 10 },
];

class LaunchSurvey extends Component {
  constructor(props) {
    super(props);
    this.targetDivRef = React.createRef();
    this.state = {
      imgSrc: "",
      title: "",
      description: "",
      imageName: "",
      addLimitCheckbox: false,
      step: 1,
      buttonClickScrollTop: false,
      isEdit: false,
      loading: false,
      imageUpdate: 0,
      surveyCategories: [],
      selectedSurveyCategory: null,
      selectedSurveyValue: null,
      selectedSurveyPoint: null,
      companyId: [props.companyInfo["id"]],
      createdSurveyDetails: {},
      surveyQuestionTypes: [],
      questionAnswers: [
        {
          questionType: {},
          question: "",
          questionId: "",
          isFollowUp: false,
          followUpQue: "",
          isMcqWeightage: false,
          multipleMcqResponses: false,
          mcqDropDownOptions: [
            { value: 1, text: "1pt" },
            // { value: 2, text: "2pts" },
          ],
        },
      ],
      isLaunchNow: false,
      isSchedule: false,
      openDateTimePicker: "",
      date: dayjs(),
      endDate: null,
      showDateTimeInput: "",
      endType: "date",
      mcqpoints: [
        { value: 1, text: "1pt" },
        { value: 2, text: "2pts" },
      ],
      showCreateSurveyPopUp: false,
      showQueReorderPopUp: false,
      disable: true,
      participantLimit: "",
      isActiveOrUpcoming: false,
      messageToEmployeeCharacterCount: 0,
      usersToInvite:0,
      allTeams:[],
      companyInfo:null,
      city_location: [],
      departments:[],
      teams:[],
      launch:0,
      isDependent:false,
      isSpouse:false,
      isEmployee: false,
      emailOption: {
        creation_email: 0,
        reminder_email: 0,
        completion_email: 1,
      },
      selectedCompanyCheckBox: 0,
      companies: [],
      filteredCompanies:[],
      searchTerm: '',
      buttonstatus:false,
    };
  }

  _isMounted = false;

  componentDidMount() {
    const { getCompanyDepartment, getCompanyLocation, allowChallengeEventForSpouseDependent, fetchCompanyForUser, role} = this.props;
    this._isMounted = true;
    const editData = this.props.history.location.state;

    if (editData && editData.surveyId ) {
      this.getSurveyDetails(editData.surveyId);
    }

    this.fetchSurveyCategories();
    this.fetchQuestionTypes(editData);

    document.addEventListener("mousedown", this.handleClick);
    getCompanyDepartment(this.props.companyDetails.id);
    getCompanyLocation(this.props.companyDetails.id);
    this.getTeams(this.props.companyDetails.id);
    this.getCompanyInfo(this.props.companyDetails.id);
    allowChallengeEventForSpouseDependent();
    fetchCompanyForUser();
    this.setState({
      companies: (role != "ADMIN" && this.props.userCompany) ? [this.props.userCompany.id] : []
    }); 
    if (!this.props.allCompanies) {
      this.props.fetchAllCompanies();
    }else{
      this.setState({filteredCompanies:this.props.allCompanies})
    }

  }
  
  getTeams = async(companyId)=>{
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.get(`${GetTeams}/${companyId}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({
          allTeams:res
        })
      }
    }catch(error){
      toast.error(error.message);
    }
  }

  getCompanyInfo = async(companyId)=>{
    let apiUrl = GetCompanyInfo + "/" + companyId;
    try {
      const res = await fetchApi(apiUrl , "GET");
      if (res.status === "success") {
        this.setState({companyInfo: res?.data?.companyInfo?.[0]})

      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.log(error);
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClick);
  }

  componentDidUpdate(prevProps) {
    if (this.state.buttonClickScrollTop) {
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        this.setState({ buttonClickScrollTop: false });
      }, 2000);
    }

    if (
      !this.state.companiesPermission &&
      this.props.userCompany !== prevProps.userCompany
    ) {
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id],
      });
    }
    if(prevProps.allCompanies != this.props.allCompanies){
      this.setState({filteredCompanies:this.props.allCompanies})
    }
  }

  getSurveyDetails = async (libraryId) => {    
    this.setState({loading:true});
    let apiUrl = GetSurveyLibraryDetails + `/${libraryId}`;
    try {
      const res = await fetchApi(apiUrl , "GET");
      console.log("res",res)
      if (res?.status === "success") {
   
        const{title,description,image,library_questions,category_id,category_name}=res.data;
   
        this.setState({title:title,description:description,imgSrc:image,imageName:image,selectedSurveyValue:category_id,selectedSurveyCategory:category_name,});
        this.setQuestionAnswers(library_questions);
        this.setState({loading:false});
   
      } else {
        toast.error(res?.error);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  changeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if (showDateTimeInput === "date") {
      this.setState({
        date: moment(e._d).format("MM/DD/YYYY"),
        time: moment(e._d).format("hh:mm a"),

        endTime: "11:59 pm",
        openDateTimePicker: "",
      });
    } else if (showDateTimeInput === "endDate") {
      this.setState({ endDate: moment(e._d).format("MM/DD/YYYY") });
    } else if (stateName.includes("ate")) {
      this.setState({
        openDateTimePicker: "",
        [stateName]: moment(e._d).format("MM/DD/YYYY"),
      });
    } else {
      this.setState({
        [stateName]: moment(e._d).format("hh:mm a"),
        openDateTimePicker: stateName,
      });
    }
  };

  handleClick = (e) => {
    if (
      typeof this.datePickerWrapper === "undefined" &&
      this.datePickerWrapper === null
    ) {
      return;
    }
    if (
      !_.isNull(this.datePickerWrapper) &&
      !_.isUndefined(this.datePickerWrapper) &&
      this.datePickerWrapper &&
      !this.datePickerWrapper.contains(e.target)
    ) {
      this.setState({
        openDateTimePicker: "",
      });
    }
  };

  setQuestionAnswers = (data) => {
    console.log("data", data);
  
    const questionAns = data.map((item) => {
      const mcqOptions = [{ text: "1 pt", value: 1 }];
  
      // Normalize question type name
      let modifiedName = item.question_type_name.replace(/-/g, " ");
      if (modifiedName === "yes no") modifiedName = "yes or no";
      if (modifiedName === "short answer") modifiedName = "short description";
  
      const queAnsObj = {
        questionId: item.id,
        followUpQue: item.follow_up_question,
        isFollowUp: !!item.follow_up_question_id,
        questionType: {
          id: item.question_type_id,
          name: item.question_type_name,
          text: modifiedName,
        },
        question: item.question,
        answerId: item.question_answers?.[0]?.id,
        multipleMcqResponses: !!item.accept_multiple_responses,
        answers: [],
        isupdated: false,
      };
  
      // Process answers
      if (item.question_answers?.length > 0) {
        if (item.question_type_name === "opinion-scale" && item.question_answers.length > 10) {
          const isZeroPoints = item.question_answers[0].points === 0;
          queAnsObj.answers = [
            {
              label: item.question_answers[isZeroPoints ? 0 : 10].answer,
              points: item.question_answers[isZeroPoints ? 0 : 10].points,
              text: `${isZeroPoints ? 1 : 10}pt${isZeroPoints ? "" : "s"}`,
            },
            {
              label: item.question_answers[isZeroPoints ? 10 : 0].answer,
              points: item.question_answers[isZeroPoints ? 10 : 0].points,
              text: `${isZeroPoints ? 10 : 1}pt${isZeroPoints ? "s" : ""}`,
            },
          ];
        } else {
          queAnsObj.answers = item.question_answers.map((answer) => {
            const ansObj = {
              points: answer.points,
              label: answer.answer,
              text: `${answer.points}pt${answer.points > 1 ? "s" : ""}`,
            };
            if (item.question_type_name === "multiple-choice" && answer.points) {
              queAnsObj.isMcqWeightage = true;
              queAnsObj.mcqDropDownOptions = mcqOptions;
            }
            return ansObj;
          });
        }
      }
  
      console.log("queAnsObj", queAnsObj);
      return queAnsObj;
    });
  
    console.log("questionAns", questionAns);
  
    this.setState({ questionAnswers: questionAns }, () => {
      console.log("questionAnswers", this.state.questionAnswers);
      // Uncomment if needed
      // this.areAllValuesNotEmpty(this.state.questionAnswers);
    });
  };


  fetchSurveyCategories = async () => {
    let apiUrl = GetSurveyCategories;
    try {
      const res = await fetchApi(apiUrl , "GET");
      if (res?.categories) {
        this.setState({surveyCategories: res?.categories})

      } else {
        toast.error(res?.error);
      }
    } catch (error) {
      window.console.log(error);
    }

  }

  fetchQuestionTypes = async () => {
    let apiUrl = GetSurveyQuestionTypes;
    try {
      const res = await fetchApi(apiUrl , "GET");
      if (res?.["question-types"]) {
        let questionTypes = res["question-types"];
        let modifiedQuestionTypes = questionTypes.map((questionType) => {
          let modifiedName = questionType.name.replace(/-/g, " "); // Remove hyphens
          if (modifiedName === "yes no") {
            modifiedName = "yes or no"; // If name is "yes-no", set it to "yes"
          } else if (modifiedName === "short answer") {
            modifiedName = "short description";
          }
          return {
            id: questionType.id,
            text: modifiedName,
            name: questionType.name,
          };
        });

        this.setState({surveyQuestionTypes: modifiedQuestionTypes})

      } else {
        toast.error(res?.error);
      }
    } catch (error) {
      window.console.log(error);
    }

  }

  checkBase64(image) {
    if(image) {
      let arr = image.split('/');
      console.log("arr",arr);
      if(arr[0] !== 'survey-library-images' && arr[0] !== 'survey-images' && arr[0] !== 'survey-library-image'&& arr[0] !== 'survey-image'&& arr[0] !== "survey-library") {
        console.log("hi image")
        return true;
      }
      else {
        return false;
      }
    }
  }


  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if (file?.size / 1000000 <= 1) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document
            .getElementById("event-upload-file")
            .value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage,
                  imageUpdate: 1,
                });
              });
            });
          };
        } else {
          toast.error("Please select image file less than 1MB");
          document.getElementById("event-upload-file").value = "";
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById("event-upload-file").value = "";
      }
    }
  };

  onChangeInput = (e) => {
    if (e.target.value.charAt(0) !== " ") {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  removePhoto = () => {
    this.setState({
      imgSrc: "",
      imageName: "",
    });
  };


  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, "");
    trimmedString.length === 0 ||
    trimmedString.length === 7 ||
    trimmedString == "<p></p><p></p>"
      ? this.setState({ description: "" })
      : this.setState({ description: newContent });
  };

  updateStep = (value) => {
    this.setState({ step: value, buttonClickScrollTop: true });
    // let questionArr = [];
    // let answerArr = [];
    // if(value===3){
    //   this.state.questionAnswers.map((data,index)=>{
    //     let obj={};
    //     let ansObj={};
    //     obj.type=data.questionType.id;
    //     obj.weight=index+1;
    //     obj.question=data.question;
    //     if(data.questionType.id===3){
    //       obj.accept_multiple_responses=data.multipleMcqResponses?true:false;
    //     }
    //     if(data.questionType.id!==1){
    //       ansObj.question_type=data.questionType.id;
    //       if(data.questionType.id===3){
    //         ansObj.answer = data.answers.map((answer) => ({ value: answer.label, points: answer.points }))}
    //       else if(data.questionType.id===5){
    //         if(data.answers[0].points === 1){
    //           ansObj.answer=likertScaleValues
    //         }else{
    //           ansObj.answer=likertScaleValues.slice().sort((a, b) => b.points - a.points)
    //         }
    //       }
    //       else if(data.questionType.id===2){
    //         if(data.answers[0].points === 1){
    //           ansObj.answer=opinionScaleValues
    //         }else{
    //           ansObj.answer=opinionScaleValues.slice().sort((a, b) => b.points - a.points)
    //         }
    //       }
    //       else{
    //         ansObj.answer=data.answers;
    //       }
    //       answerArr.push(ansObj)
    //     }
    //     questionArr.push(obj);
    //   })
    // }
  };

  close = () => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_14978_75731"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <rect width={24} height={24} fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_14978_75731)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3605 0.678084C22.6358 -0.0466026 21.4608 -0.0466023 20.7361 0.678084L11.9067 9.50756L3.07795 0.678849C2.4145 0.0154025 1.33884 0.0154032 0.675399 0.678849C0.0119522 1.3423 0.0119521 2.41796 0.675399 3.0814L9.50411 11.9101L0.675642 20.7386C-0.0490441 21.4633 -0.0490441 22.6382 0.675643 23.3629C1.40033 24.0876 2.57528 24.0876 3.29996 23.3629L12.1284 14.5344L20.9577 23.3637C21.6211 24.0271 22.6968 24.0271 23.3602 23.3637C24.0237 22.7002 24.0237 21.6246 23.3602 20.9611L14.531 12.1319L23.3605 3.30241C24.0851 2.57772 24.0851 1.40277 23.3605 0.678084Z"
          fill="#9C9C9C"
        />
      </g>
    </svg>
  );

  renderHeading = () => (
    <ChallengeHeading color={"#005c87"}>
      <div style={{marginRight:"12px"}}>
        <HoverButton
          title={this.props.t("Close")}
          width="24px"
          height="24px"
          svgPath={this.close()}
          onClick={() => this.props.history.goBack()}
          activeColor={"#007AB1"}
          fillOpacity={1}
        />
      </div>
      {this.props.t("Launch the survey")}
    </ChallengeHeading>
  );

  stepOneFormData = () => {
    const {
      title,
      description,
      step,
      imgSrc
    } = this.state;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color="#005C87">
            <div className="stepsNameHighlight">
              <StepNames stepCount={step} type="survey" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step" >{step}</div>
                <div className="headingName" style={{color:"#005c87"}}>{this.props.t("Survey Introduction")}</div>
              </div>
              <div className="formBody">
                <InputContainer>
                  <StyledLabel color={"#005c87"}>{this.props.t("Title of the survey")}{<span>*</span>}</StyledLabel>
                  <StyledInputV2
                    placeholder={"Write title here..."}
                    name="title"
                    onChange={this.onChangeInput}
                    value={title}
                    maxLength="100"
                    color="#005c87"
                    border={"1px solid #afcbd3"}
                  />
                </InputContainer>

                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>

                <StyledLabel style={{marginTop:"0px"}} color={"#005c87"}>{this.props.t("Survey image")}{<span>*</span>}</StyledLabel>
                <UploadPhotoContainer>
                  <div className="innerDivImageContainer">
                    {this.state.imgSrc ? (
                      <RemoveImageV2 >
                        <img alt="profilre-img" src={this.checkBase64(imgSrc) ? imgSrc : `${ImageUrl}/${imgSrc}`}></img>
                        <div className="main-div" onClick={this.removePhoto}>
                          <div className="icon-div" ><div className="sub-icon-div">{closeIcon("white","8","8")}</div></div>
                        </div>
                      </RemoveImageV2>
                    ) : (
                      <div className="uploadImage">
                        <InputImage
                          className="edit-profile-pic "
                          imageReload={1}
                          style={{background:"none"}}
                        >
                          <div className="middle-section">
                            <div>{UploadIcon()}</div>
                            <div className="title">{this.props.t("Upload File")}</div>
                            <div className="sub-title">({this.props.t(".jpeg, .png")})</div>
                            
                          </div>
                          {/* <i className="addImg">{this.props.t("Upload Image")}</i> */}
                          <input
                            id="event-upload-file"
                            type="file"
                            name="user"
                            accept=".jpeg, .png, .jpg"
                            multiple={false}
                            onChange={(e) => this.onChange(e)}
                            onClick={(e) =>
                              e.target.files[0] && this.onChange(e)
                            }
                          />
                        </InputImage>
                      </div>
                    )}
                  </div>
                </UploadPhotoContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>
                <InputContainer>
                  <StyledLabel style={{marginTop:"0px"}} color={"#005c87"}>{this.props.t("About the survey")}{<span>*</span>}</StyledLabel>
                  <EditorContainer>
                    <Editor
                      content={description}
                      onChange={this.onChangeDescription}
                    />
                  </EditorContainer>
                </InputContainer>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  stepOneForm = () => {
    const {
      title,
      description,
      step,
      surveyCategories,
      selectedSurveyCategory,
      selectedSurveyPoint,
      selectedSurveyValue
    } = this.state;
    const { t } = this.props;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="survey" /> 
            </div>
            <div className="form" style={{ paddingBottom: "75px" }}>
              <div className="heading">
                <div className="step" style={{ background: "#85C0EA" }}>
                  {step}
                </div>
                <div
                  className="headingName"
                  style={{
                    color: "#005C87",
                    fontFamily: "Rubik-Medium",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "24px",
                  }}
                >
                  Introduce the Survey to your team
                </div>
              </div>
              <div className="formBody">
                <InputContainer>
                  <StyledLabel
                    style={{
                      color: "#005C87",
                      fontFamily: "Rubik-Regular",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    Title of the survey{<span>*</span>}
                  </StyledLabel>
                  <StyledInputV2
                    placeholder={t("Write title here...")}
                    name="title"
                    onChange={this.onChangeInput}
                    value={title}
                    // maxLength="50"
                    style={{
                      background: "rgba(247, 245, 235, 0.50)",
                      border: "1px solid rgba(0, 92, 135, 0.30)",
                      color: "#005C87",
                      fontFamily: "Rubik-Regular",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "24px",
                    }}
                  />
                </InputContainer>
                <StyledLabel
                  style={{
                    color: "#005C87",
                    fontFamily: "Rubik-Regular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Survey image{<span>*</span>}
                </StyledLabel>
                <UploadPhotoContainer>
                  <div className="innerDivImageContainer">
                    {this.state.imgSrc ? (
                      <img alt="profilre-img" src={this.state.imgSrc}></img>
                    ) : (
                      <div
                        className="uploadImage"
                        style={{
                          background: "rgba(247, 245, 235, 0.50)",
                          border: "1px solid rgba(0, 92, 135, 0.30)",
                        }}
                      >
                        <InputImage
                          className="edit-profile-pic "
                          style={{ background: "#005C87" }}
                          imageReload={1}
                        >
                          <i className="addImg">{"Upload Photo"}</i>
                          <input
                            id="event-upload-file"
                            type="file"
                            name="user"
                            accept=".jpeg, .png, .jpg"
                            multiple={false}
                            onChange={(e) => this.onChange(e)}
                            onClick={(e) =>
                              e.target.files[0] && this.onChange(e)
                            }
                          />
                        </InputImage>
                      </div>
                    )}
                    {this.state.imgSrc.length === 0 ? null : (
                      <RemoveImage onClick={this.removePhoto}>
                        {"Remove Image"}
                      </RemoveImage>
                    )}
                  </div>
                </UploadPhotoContainer>

                <StyledLabel
                  style={{
                    color: "#005C87",
                    fontFamily: "Rubik-Regular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <span style={{color: "#005C87"}}>Message to employees - template below:{<span style={{color: "red"}}>*</span>}</span>
                  <span style={{color: "#005c8766"}}>{this.state.description.length}/1500</span>

                </StyledLabel>

                <CommonTextArea
                  placeholder="Enter message to employees"
                  rows="3"
                  name="description"
                  data-gramm_editor="false"
                  onChange={this.onChangeInput}
                  value={description}
                  maxLength={1500}
                  style={{
                    marginTop: "0px",
                    background: "rgba(247, 245, 235, 0.50)",
                    border: "1px solid rgba(0, 92, 135, 0.30)",
                    color: "#005C87",
                    fontFamily: "Rubik-Regular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                />

                <StyledLabel
                  style={{
                    marginBottom: "0px",
                    color: "#005C87",
                    fontFamily: "Rubik-Regular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Set survey category{<span>*</span>}
                </StyledLabel>

                <DropDown
                  data={surveyCategories}
                  placeholder="Select option"
                  valueIndex={0}
                  dropdownStyle={{ top: "30px" }}
                  onSelectParameter={["name", "id"]}
                  itemValue={true}
                  activeValue={true}
                  title={selectedSurveyCategory.name}
                  displayValue={true}
                  valueString={""}
                  tileContainerStyle={{
                    width: "100%",
                    // background: "rgba(247, 245, 235, 0.50)",
                    position: "absolute",
                    border: "1px solid rgba(0, 92, 135, 0.30)",
                    color: "#005C87",
                    fontFamily: "Rubik-Regular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                  active={selectedSurveyValue}
                  onSelect={(value) => {
                    this.setState({ selectedSurveyCategory: value });
                  }}
                  activityDropdownStyle={{
                    background: "rgba(247, 245, 235, 0.50)",
                  }}
                />

                <StyledLabel
                  style={{
                    marginBottom: "0px",
                    color: "#005C87",
                    fontFamily: "Rubik-Regular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Set point value{<span>*</span>}
                </StyledLabel>

                <DropDown
                  data={surveypoints}
                  placeholder="Select option"
                  valueIndex={0}
                  dropdownStyle={{ top: "30px" }}
                  onSelectParameter={["text", "value"]}
                  itemValue={true}
                  activeValue={true}
                  title={selectedSurveyPoint.text}
                  displayValue={true}
                  valueString={""}
                  tileContainerStyle={{
                    width: "100%",
                    // background: "rgba(247, 245, 235, 0.50)",
                  }}
                  active={selectedSurveyPoint.value}
                  onSelect={(value) => {
                    this.setState({ selectedSurveyPoint: value });
                  }}
                  activityDropdownStyle={{
                    background: "rgba(247, 245, 235, 0.50)",
                  }}
                />
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  onQueTypeSelect = (index, value, /*item*/) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].questionType = value;
    if (value.name === "opinion-scale") {
      questionAnswers[index].answers = [
        { label: "", points: 0, text: "0pts" },
        { label: "", points: 10, text: "10pts" },
      ];
    } else if (value.name === "multiple-choice") {
      questionAnswers[index].answers = [
        { label: "", points: 1, text: "1pt" },
        { label: "", points: 1, text: "1pt" },
      ];
      questionAnswers[index].mcqDropDownOptions = [
        { value: 1, text: "1pt" },
        // { value: 2, text: "2pts" },
      ];
      questionAnswers[index].isMcqWeightage = true;
      questionAnswers[index].multipleMcqResponses = false;
    } else if (value.name === "yes-no") {
      questionAnswers[index].answers = [
        { label: "Yes", points: 5, text: "5pts" },
        { label: "No", points: 1, text: "1pt" },
      ];
    } else if (value.name === "likert-scale") {
      questionAnswers[index].answers = JSON.parse(JSON.stringify(likertScaleValues));
    }
    this.setState({ questionAnswers: questionAnswers });

  };

  onQueChange = (index, event) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].question = event.target.value;
    this.setState({ questionAnswers: questionAnswers });
    this.areAllValuesNotEmpty(questionAnswers);
  };

  onFollowUpToggle = (index, name, /*item*/) => {
    const { questionAnswers } = this.state;
    questionAnswers[index][name] = !questionAnswers[index][name];
    this.setState({ questionAnswers: questionAnswers }, () => {
      if (name == "multipleMcqResponses") {

        if (questionAnswers[index]["multipleMcqResponses"]) {
          questionAnswers[index]["isMcqWeightage"] = false;
        } else {
          questionAnswers[index]["isMcqWeightage"] = true;

          // for (let i = 0; i < questionAnswers[index]["answers"].length; i++) {
          //   (questionAnswers[index]["answers"][i].text =
          //     i + 1 > 1 ? i + 1 + "pts" : i + 1 + "pt"), (questionAnswers[index]["answers"][i].points = i + 1);
          // }
        }
        this.setState({ questionAnswers: questionAnswers });
      }

    });
  };

  addNewQue = () => {
    let questionAnswers = [...this.state.questionAnswers];
    let allsurveyQuestionTypes = [...this.state.surveyQuestionTypes];
    let shortDesc = allsurveyQuestionTypes.filter(
      (item) => item.name === "short-answer"
    );
    questionAnswers.push({
      questionType: shortDesc[0],
      question: "",
      isFollowUp: false,
    });
    this.setState({ questionAnswers: questionAnswers }, () =>
      this.areAllValuesNotEmpty(this.state.questionAnswers)
    );
  };


  onFollowUpQueChange = (index, event) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].followUpQue = event.target.value;
    this.setState({ questionAnswers: questionAnswers });
  };

  onOpinionLabelChange = (index, e, labelIndex) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].answers[labelIndex].label = e.target.value;
    this.setState({ questionAnswers: questionAnswers });
  };

  onOpinionPointChange = (index, value, labelIndex) => {
    const { questionAnswers } = this.state;
    // questionAnswers[index].opinionScale[labelIndex].value = value.value;
    if (labelIndex === 0 && value.value === 0) {
      questionAnswers[index].answers[labelIndex].text = value.text;
      questionAnswers[index].answers[labelIndex].points = value.value;
      questionAnswers[index].answers[1].text = "10pts";
      questionAnswers[index].answers[1].points = 10;
    } else if (labelIndex === 0 && value.value === 10) {
      questionAnswers[index].answers[labelIndex].text = value.text;
      questionAnswers[index].answers[labelIndex].points = value.value;
      questionAnswers[index].answers[1].text = "0pts";
      questionAnswers[index].answers[1].points = 0;
    }
    if (labelIndex === 1 && value.value === 0) {
      questionAnswers[index].answers[labelIndex].text = value.text;
      questionAnswers[index].answers[labelIndex].points = value.value;
      questionAnswers[index].answers[0].text = "10pts";
      questionAnswers[index].answers[0].points = 10;
    } else if (labelIndex === 1 && value.value === 10) {
      questionAnswers[index].answers[labelIndex].text = value.text;
      questionAnswers[index].answers[labelIndex].points = value.value;
      questionAnswers[index].answers[0].text = "0pts";
      questionAnswers[index].answers[0].points = 0;
    }

    this.setState({ questionAnswers: questionAnswers });
  };

  onLabelChange = (index, e, labelIndex, name) => {
    const { questionAnswers } = this.state;
    questionAnswers[index][name][labelIndex].label = e.target.value;
    this.setState({ questionAnswers: questionAnswers });
  };

  onLikeartPointChange = (index, value, labelIndex, name) => {
    const { questionAnswers } = this.state;
    const maxPoints = 5;
  
    // Loop through all dropdowns
    for (let i = 0; i < maxPoints; i++) {
      const adjustedPoints = value.value === 1 ? i + 1 : maxPoints - i;
  
      questionAnswers[index][name][i].text = `${adjustedPoints}pts`;
      questionAnswers[index][name][i].points = adjustedPoints;
  
      // Enable only the first dropdown, disable the rest
      questionAnswers[index][name][i].disabled = i !== 0;
    }
  
    this.setState({ questionAnswers });
  };

  onYesNoPointChange = (index, value, labelIndex, name) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].isupdated = true;
    const oppositeIndex = labelIndex === 0 ? 1 : 0;
    const oppositePoints = value.value === 1 ? 5 : 1;
  
    questionAnswers[index][name][labelIndex].text = value.text;
    questionAnswers[index][name][labelIndex].points = value.value;
    questionAnswers[index][name][oppositeIndex].text = `${oppositePoints}pts`;
    questionAnswers[index][name][oppositeIndex].points = oppositePoints;
  
    this.setState({ questionAnswers });
  };

  onMcqPointChange = (index, value, labelIndex, name) => {
    const { questionAnswers } = this.state;

    questionAnswers[index][name][labelIndex].text = value.text;
    questionAnswers[index][name][labelIndex].points = value.value;

    this.setState({ questionAnswers: questionAnswers });
  };

  deleteMcqOption = (item, index, ind) => {
    const { questionAnswers } = this.state;

    const modifiedQuestionAnswers=[...questionAnswers];
    modifiedQuestionAnswers[index].answers.splice(ind, 1);

    // let modifiedmcqpoints = [];

    // questionAnswers[index].mcqDropDownOptions = modifiedmcqpoints;

    this.setState(
      { questionAnswers: modifiedQuestionAnswers }
    );
  };

  addOptions = (index) => {
    const { questionAnswers, mcqpoints } = this.state;

    if (questionAnswers[index].answers.length < 10) {
      const newOption = {
        label: "",
        points:  1,
        text:
          1 + "pt",
      };

      questionAnswers[index].answers.push(newOption);

      // Update the state with the new option
      this.setState({ questionAnswers: questionAnswers, mcqpoints: mcqpoints });
    }
  };

  deleteQuestionAns = async (index) => {

    let questions = [...this.state.questionAnswers]
    questions.splice(index, 1);
    this.setState({questionAnswers:questions});

  };

  onSelectPoints = (name, /*value, key*/) => {
    this.setState({
      selectedSurveyPoint: name.value
    });
  };

  onSelectCategory = (name, /*value, key*/) => {
    this.setState({
      selectedSurveyCategory: name.name,
      selectedSurveyValue: name.id
    });
  };

  stepTwoForm = () => {
    const {
      step,
      surveyQuestionTypes,
      questionAnswers,
      // mcqpoints,
      isActiveOrUpcoming,
      selectedSurveyPoint,
      // isFollowUp,
      selectedSurveyCategory,
      surveyCategories
    } = this.state;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="survey" />
            </div>
            <div className="form">
              <div
                className="heading"
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ display: "flex" }}>
                  <div className="step" style={{ background: "#005C87" }}>
                    {step}
                  </div>
                  <div
                    className="headingName"
                    style={{
                      color: "#005C87",
                      fontFamily: "Rubik-Medium",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "24px",
                    }}
                  >
                    How would you like to customize your survey?
                  </div>
                </div>
                {!isActiveOrUpcoming && (
                  <div style={{ display: "flex", marginRight: "15px" }}>
                    <HoverButton
                      title={"Add Question"}
                      svgPath={addQueIcon()}
                      onClick={this.scrollToDiv}
                    />
                    <HoverButton
                      title={"Reorder Questions"}
                      svgPath={reorderIcon()}
                      onClick={() =>
                        this.setState({ showQueReorderPopUp: true })
                      }
                    />
                  </div>
                )}
              </div>
              <CommonDropdown
                tileContainerStyle={{ width: "100%", padding:"0px 0px 20px 0px", margin: "20px 0 0 0" }}
                dropdownStyle={{ top: "95px" }}
                labelText={"Survey point value"}
                isRequired={true}
                dropdownOpen={this.state.so}
                placeholder={this.props.t("Select point value")}
                title={
                  selectedSurveyPoint
                }
                id="dropdown-recurring"
                onClick={() => this.setState({ so: !this.state.so })}
                data={surveypoints}
                active={selectedSurveyPoint}
                onSelectParameter={["selectedSurveyPoint", "value", "text"]}
                // valueString={"Points"}
                itemValue={true}
                valueIndex={2}
                labelMargin={"0px"}
                onSelect={this.onSelectPoints}
              />
              <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px', display:"flex"}}/>


              {/* <DropDown
                  data={surveyCategories}
                  placeholder="Select Option"
                  valueIndex={0}
                  dropdownStyle={{ top: "30px" }}
                  onSelectParameter={["name", "id"]}
                  itemValue={true}
                  activeValue={true}
                  title={selectedSurveyCategory.name}
                  displayValue={true}
                  valueString={""}
                  tileContainerStyle={{
                    width: "100%",
                    // background: "rgba(247, 245, 235, 0.50)",
                    position: "absolute",
                    border: "1px solid rgba(0, 92, 135, 0.30)",
                    color: "#005C87",
                    fontFamily: "Rubik-Regular",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                  active={selectedSurveyCategory.id}
                  onSelect={(value) => {
                    this.setState({ selectedSurveyCategory: value });
                  }}
                  activityDropdownStyle={{
                    background: "rgba(247, 245, 235, 0.50)",
                  }}
                /> */}
              <CommonDropdown
                tileContainerStyle={{ width: "100%", padding:"0px 0px 20px 0px", margin: "20px 0 0 0" }}
                dropdownStyle={{ top: "95px" }}
                labelText={"Set survey category"}
                isRequired={true}
                dropdownOpen={this.state.so}
                placeholder={this.props.t("Select survey category")}
                title={
                  selectedSurveyCategory
                }
                id="dropdown-recurring"
                onClick={() => this.setState({ so: !this.state.so })}
                data={surveyCategories}
                active={selectedSurveyCategory?.id}
                onSelectParameter={["selectedSurveyCategory", "name"]}
                valueString={""}
                itemValue={true}
                valueIndex={1}
                labelMargin={"0px"}
                onSelect={this.onSelectCategory}
              />
              <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px', display:"flex"}}/>


              <div
                style={{
                  background: "white",
                  // height: "74px",
                  display: "flex",
                  alignItems: "center",
                  width:"100%",
                  marginBottom:"15px"
                }}
              >
                <span
                  style={{
                    color: "rgba(0, 92, 135, 0.60)",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  Add atleast 5 question for better data generation. min. 2 questions are required
                </span>
              </div>

              <div className="formBody" style={{ padding: "0px" }}>
                {questionAnswers.length ? questionAnswers.map((item, index) => (
                  <React.Fragment key={index}>
                    {/* <div></div> */}
                    <QueAndCard>
                      <div className="header">
                        <div>
                          <span className="title">Question {index + 1}</span>
                        </div>
                        {!isActiveOrUpcoming && (
                          <div
                            onClick={() => {
                              this.deleteQuestionAns(index);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="delete-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M16.563 2.5h-3.438v-.625C13.125.839 12.285 0 11.25 0h-2.5C7.714 0 6.875.84 6.875 1.875V2.5H3.437c-.862 0-1.562.7-1.562 1.563v1.25c0 .345.28.625.625.625h15c.345 0 .625-.28.625-.625v-1.25c0-.863-.7-1.563-1.563-1.563Zm-8.438-.625c0-.345.28-.625.625-.625h2.5c.345 0 .625.28.625.625V2.5h-3.75v-.625ZM3.06 7.188c-.111 0-.2.093-.195.204l.516 10.822A1.873 1.873 0 0 0 5.254 20h9.491a1.873 1.873 0 0 0 1.873-1.786l.515-10.822a.195.195 0 0 0-.195-.205H3.061ZM12.5 8.75a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Zm-3.126 0a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Zm-3.125 0a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Z"
                                  fill="#005C87"
                                  fillOpacity=".6"
                                />
                              </svg>
                            </span>
                          </div>
                        )}
                      </div>
                      <Border
                        style={{
                          background: "rgba(0, 92, 135, 0.30)",
                          margin: "15px 0px",
                        }}
                      />
                      <CommonDropdown
                        data={surveyQuestionTypes}
                        label={"Your type of question"}
                        placeholder="Select type of question"
                        valueIndex={0}
                        dropdownStyle={{ top: "30px" }}
                        onSelectParameter={["text", "id"]}
                        itemValue={true}
                        activeValue={true}
                        title={item?.questionType?.text}
                        displayValue={true}
                        onClick={() => this.setState({ so: !this.state.so })}
                        valueString={""}
                        tileContainerStyle={{
                          width: "100%",
                          // background: "rgba(247, 245, 235, 0.50)",
                          padding: "0px",
                          // color:"#005C87"
                        }}
                        background={
                          isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                        }
                        active={item?.questionType?.id}
                        onSelect={(value) => {
                          this.onQueTypeSelect(index, value, item);
                        }}
                        disabled={isActiveOrUpcoming}
                        height={"60px"}
                        icon={
                          item?.questionType?.name == "short-answer" ? (
                            shortDescIcon()
                          ) :  item?.questionType?.name == "opinion-scale" ? (
                            opinionScaleIcon()
                          ) :  item?.questionType?.name == "multiple-choice" ? (
                            mcqIcon()
                          ) :  item?.questionType?.name == "yes-no" ? (
                            yesnoIcon()
                          ) :  item?.questionType?.name == "likert-scale" ? (
                            likertScaleIcon()
                          ) : (
                            <div
                              style={{
                                background: "white",
                                width: "40px",
                                height: "40px",
                                borderRadius: "3px",
                              }}
                            ></div>
                          )
                        }
                      />
                      <InputContainer>
                        <StyledLabel
                          style={{
                            color: "#005C87",
                            fontFamily: "Rubik-Regular",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "20px",
                          }}
                        >
                          What would you like to ask?{<span>*</span>}
                        </StyledLabel>
                        {/* <StyledInputV2
                          placeholder={"Enter the question you want ask"}
                          name="title"
                          onChange={(e) => this.onQueChange(index, e)}
                          value={item.question}
                          // maxLength="50"
                        /> */}
                        <DynamicStyledTextArea
                          key={index}
                          index={index}
                          placeholder={"Enter the question you want to ask"}
                          name={`question-${index}`}
                          onChange={this.onQueChange}
                          value={item.question}
                          maxLength={200}
                        />
                      </InputContainer>

                      { item?.questionType?.name === "opinion-scale" ? (
                        <div className="opinion-scale">
                          <div className="header">
                            <span
                              className="title"
                              style={{ marginTop: "0px" }}
                            >
                              10 Point Scale
                            </span>
                          </div>
                          <div className="point-scale">
                            <div className="point-label">
                              {/* <div>{`"${item &&  item.opinionScale  && item.opinionScale[0].points}" label`}</div> */}
                              <div
                                style={{
                                  color: "rgba(0, 92, 135, 0.60)",
                                  fontFamily: "Rubik-Regular",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                }}
                              >{`"0" label`}</div>
                              <div className="input-label">
                                <InputContainer>
                                  <StyledInputV2
                                    placeholder={"eg. it was bad"}
                                    name="title"
                                    onChange={(e) =>
                                      this.onOpinionLabelChange(index, e, 0)
                                    }
                                    value={
                                      item?.answers?.[0]?.label
                                    }
                                    maxLength="50"
                                    style={{
                                      // background: "rgba(247, 245, 235, 0.50)",
                                      border:
                                        "1px solid rgba(0, 92, 135, 0.30)",
                                      color: isActiveOrUpcoming
                                        ? "rgba(0, 92, 135,0.6)"
                                        : "rgba(0, 92, 135)",
                                      fontFamily: "Rubik-Medium",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                    }}
                                    background={
                                      isActiveOrUpcoming &&
                                      "rgba(0, 92, 135, 0.05)"
                                    }
                                    disabled={isActiveOrUpcoming}
                                  />
                                </InputContainer>
                              </div>
                            </div>
                            <div className="point-dropdown">
                              <CommonDropdown
                                data={opinionpoints}
                                // label={"Your type of question"}
                                placeholder="Select points"
                                valueIndex={0}
                                dropdownStyle={{ top: "30px" }}
                                onSelectParameter={["text", "id"]}
                                itemValue={true}
                                activeValue={true}
                                title={
                                  item?.answers?.[0]?.text
                                }
                                displayValue={true}
                                valueString={""}
                                tileContainerStyle={{
                                  width: "110px",
                                  // background: "#F7F5EB",
                                  padding: "0px",
                                  marginRight: "25px",
                                }}
                                background={
                                  isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                                }
                                disabled={isActiveOrUpcoming}
                                active={
                                  item?.answers?.[0]?.points
                                }
                                onSelect={(value) => {
                                  this.onOpinionPointChange(index, value, 0);
                                }}
                                onClick={() => this.setState({ so: !this.state.so })}
                                height={"60px"}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                margin: "20px 25px 0px 25px",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="18"
                                viewBox="0 0 44 18"
                                fill="none"
                              >
                                <rect
                                  y="6"
                                  width="44"
                                  height="6"
                                  rx="3"
                                  fill="#005C87"
                                />
                                <circle
                                  cx="22"
                                  cy="9"
                                  r="8.5"
                                  fill="#F4AAA9"
                                  stroke="#fff"
                                />
                                <path
                                  d="M20 7h4m-6 2h8m-6 2h4"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>

                            <div className="point-label">
                              {/* <div>{`"${item &&  item.answers && item.answers[1].points}" label`}</div> */}
                              <div
                                style={{
                                  color: "rgba(0, 92, 135)",
                                  fontFamily: "Rubik-Regular",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                }}
                              >{`"10" label`}</div>
                              <div className="input-label">
                                <InputContainer>
                                  <StyledInputV2
                                    placeholder={"eg. it was Perfect"}
                                    name="title"
                                    onChange={(e) =>
                                      this.onOpinionLabelChange(index, e, 1)
                                    }
                                    value={
                                      item?.answers?.[1]?.label
                                    }
                                    maxLength="50"
                                    style={{
                                      // background: "rgba(247, 245, 235, 0.50)",
                                      border:
                                        "1px solid rgba(0, 92, 135, 0.30)",
                                      color: isActiveOrUpcoming
                                        ? "rgba(0, 92, 135,0.6)"
                                        : "rgba(0, 92, 135)",
                                      fontFamily: "Rubik-Medium",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                    }}
                                    background={
                                      isActiveOrUpcoming &&
                                      "rgba(0, 92, 135, 0.05)"
                                    }
                                    disabled={isActiveOrUpcoming}
                                  />
                                </InputContainer>
                              </div>
                            </div>

                            <div className="point-dropdown">
                              <CommonDropdown
                                data={opinionpoints}
                                // label={"Your type of question"}
                                placeholder="Select points"
                                valueIndex={0}
                                dropdownStyle={{ top: "30px" }}
                                onSelectParameter={["text", "value"]}
                                itemValue={true}
                                activeValue={true}
                                title={
                                  item?.answers?.[1]?.text
                                }
                                displayValue={true}
                                valueString={""}
                                tileContainerStyle={{
                                  width: "110px",
                                  // background: "#F7F5EB",
                                  padding: "0px",
                                  marginRight: "25px",
                                }}
                                background={
                                  isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                                }
                                disabled={isActiveOrUpcoming}
                                active={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[1] &&
                                  item.answers[1].points
                                }
                                onSelect={(value) => {
                                  this.onOpinionPointChange(index, value, 1);
                                }}
                                onClick={() => this.setState({ so: !this.state.so })}
                                height={"60px"}
                              />
                            </div>
                          </div>
                        </div>
                      ) :  item?.questionType?.name === "likert-scale" ? (
                        <div className="opinion-scale">
                          <div className="header">
                            <span className="title">5 Point Scale</span>
                          </div>
                          {
                            item?.answers.map((ans, ind) => (
                              <div className="point-scale" key={ind}>
                                <div
                                  className="point-label"
                                  style={{ width: "auto" }}
                                  
                                >
                                  {/* <div>{`"${item.opinionScale[0].points}" label`}</div> */}
                                  <div
                                    className="input-label"
                                    style={{ width: "200px" }}
                                  >
                                    <InputContainer>
                                      <StyledInputV2
                                        placeholder={""}
                                        name="title"
                                        // readOnly
                                        onChange={(e) =>
                                          this.onLabelChange(index, e, ind, "answers")
                                        }
                                        value={
                                          ans?.label
                                        }
                                        maxLength="50"
                                      />
                                    </InputContainer>
                                  </div>
                                </div>
                                <div
                                  className="point-dropdown"
                                  style={{ marginTop: "0px" }}
                                >
                                  <CommonDropdown
                                    data={likeartpoints}
                                    placeholder="Select points"
                                    valueIndex={0}
                                    dropdownStyle={{ top: "30px" }}
                                    onSelectParameter={["text", "value"]}
                                    itemValue={true}
                                    activeValue={true}
                                    title={
                                      ans?.points > 1 ? ans?.points + " Pts" : ans?.points + " Pt"
                                    }
                                    displayValue={true}
                                    // valueString={"Points"}
                                    tileContainerStyle={{
                                      width: "110px",
                                      padding: "0px",
                                      marginLeft: "25px",
                                    }}
                                    active={
                                      item?.answers?.[0]?.points
                                    }
                                    onSelect={(value) => {
                                      this.onLikeartPointChange(
                                        index,
                                        value,
                                        0,
                                        "answers"
                                      );
                                    }}
                                    onClick={() => this.setState({ so: !this.state.so })}
                                    height={"48px"}
                                    background={
                                      isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                                    }
                                    disabled={isActiveOrUpcoming || ind > 0}
                                  />
                                </div>
                              </div>))}
                        </div>
                      ) :  item?.questionType?.name === "multiple-choice" ? (
                        <div className="opinion-scale">
                          <div className="header" style={{ marginTop: "15px" }}>
                            <span
                              className="title"
                              style={{ marginTop: "0px" }}
                            >
                              Options
                            </span>
                          </div>
                          {item &&
                            item.answers &&
                            item.answers.map((ans, ind) => (
                              <div key={ind} className="point-scale">
                                <div className="options">
                                  {String.fromCharCode(ind + 97)}
                                </div>
                                <div
                                  className="point-label"
                                  style={{ width: "300px" }}
                                >
                                  {/* <div>{`"${item.opinionScale[1].points}" label`}</div> */}
                                  <div className="input-label">
                                    <InputContainer>
                                      <StyledInputV2
                                        placeholder={"Add option"}
                                        name="title"
                                        onChange={(e) =>
                                          this.onLabelChange(
                                            index,
                                            e,
                                            ind,
                                            "answers"
                                          )
                                        }
                                        value={ans.label}
                                        maxLength="50"
                                        background={
                                          isActiveOrUpcoming &&
                                          "rgba(0, 92, 135, 0.05)"
                                        }
                                        disabled={isActiveOrUpcoming}
                                      />
                                    </InputContainer>
                                  </div>
                                </div>

                                {!item.multipleMcqResponses && (
                                  <div
                                    className="point-dropdown"
                                    style={{ marginTop: "0px" }}
                                  >
                                    <CommonDropdown
                                      data={item.mcqDropDownOptions}
                                      // label={"Your type of question"}
                                      placeholder="Select points"
                                      valueIndex={0}
                                      dropdownStyle={{ top: "30px" }}
                                      onSelectParameter={["text", "value"]}
                                      itemValue={true}
                                      activeValue={true}
                                      title={ans.text}
                                      displayValue={true}
                                      valueString={""}
                                      tileContainerStyle={{
                                        width: "110px",
                                        // background: "#F7F5EB",
                                        padding: "0px",
                                        marginRight: "25px",
                                      }}
                                      background={
                                        isActiveOrUpcoming &&
                                        "rgba(0, 92, 135, 0.05)"
                                      }
                                      disabled={isActiveOrUpcoming}
                                      active={ans.points}
                                      onSelect={(value) => {
                                        this.onMcqPointChange(
                                          index,
                                          value,
                                          ind,
                                          "answers"
                                        );
                                      }}
                                      height={"60px"}
                                      onClick={() => this.setState({ so: !this.state.so })}
                                    />
                                  </div>
                                )}

                                {item.answers.length > 2 &&
                                  !isActiveOrUpcoming && (
                                  <div
                                    onClick={() => {
                                      this.deleteMcqOption(item, index, ind);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    <span className="delete-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          d="M16.563 2.5h-3.438v-.625C13.125.839 12.285 0 11.25 0h-2.5C7.714 0 6.875.84 6.875 1.875V2.5H3.437c-.862 0-1.562.7-1.562 1.563v1.25c0 .345.28.625.625.625h15c.345 0 .625-.28.625-.625v-1.25c0-.863-.7-1.563-1.563-1.563Zm-8.438-.625c0-.345.28-.625.625-.625h2.5c.345 0 .625.28.625.625V2.5h-3.75v-.625ZM3.06 7.188c-.111 0-.2.093-.195.204l.516 10.822A1.873 1.873 0 0 0 5.254 20h9.491a1.873 1.873 0 0 0 1.873-1.786l.515-10.822a.195.195 0 0 0-.195-.205H3.061ZM12.5 8.75a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Zm-3.126 0a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Zm-3.125 0a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Z"
                                          fill="#005C87"
                                          fillOpacity=".6"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                )}
                              </div>
                            ))}

                          <div className="more-option">
                            <span
                              onClick={() =>
                                !isActiveOrUpcoming && this.addOptions(index)
                              }
                              style={{
                                color:
                                  isActiveOrUpcoming &&
                                  "rgba(0, 92, 135, 0.60)",
                                cursor: isActiveOrUpcoming && "default",
                              }}
                            >
                              Add More Option (Max 10)
                            </span>
                          </div>

                          {/* <div className="toggle-btn">
                            <ToggleButton style={{ position: "unset" }} disabled={isActiveOrUpcoming}>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item.isMcqWeightage}
                                  onChange={() => {
                                    !isActiveOrUpcoming && this.onFollowUpToggle(index,"isMcqWeightage",item);
                                  }}
                                  disabled={isActiveOrUpcoming}
                                />
                                <span className="slider round"></span>
                              </label>
                            </ToggleButton>
                            <div className="toggle-label">
                            Do you want to set weightage for Options
                            </div>
                          </div> */}

                          <div className="toggle-btn">
                            <ToggleButton
                              style={{ position: "unset" }}
                              disabled={isActiveOrUpcoming}
                            >
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item.multipleMcqResponses}
                                  onChange={() => {
                                    !isActiveOrUpcoming &&
                                      this.onFollowUpToggle(
                                        index,
                                        "multipleMcqResponses",
                                        item
                                      );
                                    // this.createSurveyQues(item, index);
                                  }}
                                  disabled={isActiveOrUpcoming}
                                />
                                <span className="slider round"></span>
                              </label>
                            </ToggleButton>
                            <div className="toggle-label">
                              Allow respondents to select multiple answers
                            </div>
                          </div>
                        </div>
                      ) :  item?.questionType?.name === "yes-no" ? (
                        <div
                          className="opinion-scale"
                          style={{ marginTop: "15px" }}
                        >
                          <div className="header" style={{ marginTop: "0px" }}>
                            <span
                              className="title"
                              style={{ marginTop: "0px" }}
                            >
                              Options
                            </span>
                          </div>

                          {item &&
                            item.answers &&
                            item.answers.map((ans, ind) => (
                              <div key={ind} className="point-scale">
                                <div className="options">
                                  {String.fromCharCode(ind + 97)}
                                </div>
                                <div
                                  className="point-label"
                                  style={{ width: "300px" }}
                                >
                                  {/* <div>{`"${item.opinionScale[1].points}" label`}</div> */}
                                  <div className="input-label">
                                    <InputContainer>
                                      <StyledInputV2
                                        placeholder={""}
                                        readOnly
                                        name="title"
                                        onChange={(e) =>
                                          this.onLabelChange(
                                            index,
                                            e,
                                            ind,
                                            "answers"
                                          )
                                        }
                                        value={ans.label}
                                        // maxLength="50"
                                        style={{
                                          background: "rgba(0, 92, 135, 0.05)",
                                          border:
                                            "1px solid rgba(0, 92, 135, 0.30)",
                                          color: "rgba(0, 92, 135, 0.60)",
                                          fontFamily: "Rubik-Medium",
                                          fontSize: "16px",
                                          fontWeight: 400,
                                          lineHeight: "20px",
                                        }}
                                      />
                                    </InputContainer>
                                  </div>
                                </div>

                                <div
                                  className="point-dropdown"
                                  style={{ marginTop: "0px" }}
                                >
                                  <CommonDropdown
                                    data={likeartpoints}
                                    // label={"Your type of question"}
                                    placeholder="Select points"
                                    valueIndex={0}
                                    dropdownStyle={{ top: "30px" }}
                                    onSelectParameter={["text", "value"]}
                                    itemValue={true}
                                    activeValue={true}
                                    title={ans.text}
                                    displayValue={true}
                                    valueString={""}
                                    tileContainerStyle={{
                                      width: "110px",
                                      // background: "rgba(247, 245, 235, 0.50)",
                                      padding: "0px",
                                      marginRight: "25px",
                                    }}
                                    active={ans.points}
                                    onSelect={(value) => {
                                      this.onYesNoPointChange(
                                        index,
                                        value,
                                        ind,
                                        "answers"
                                      );
                                    }}
                                    background={
                                      isActiveOrUpcoming &&
                                      "rgba(0, 92, 135, 0.05)"
                                    }
                                    disabled={isActiveOrUpcoming}
                                    height={"60px"}
                                    onClick={() => this.setState({ so: !this.state.so })}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      ) : null}

                      {/* {item.questionType.name != "short-answer" && <div className="follow-up">
                        <div className="title">
                          <span>Would you like more detail?</span>
                        </div>
                        <div className="toggle-btn">
                          <ToggleButton style={{ position: "unset" }} disabled={isActiveOrUpcoming}>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={item.isFollowUp}
                                onChange={() => {
                                  this.onFollowUpToggle(index, "isFollowUp", item);
                                }}
                                disabled={isActiveOrUpcoming}
                              />
                              <span className="slider round"></span>
                            </label>
                          </ToggleButton>
                          <div className="toggle-label">
                            Ask follow-up question
                          </div>
                        </div>
                      </div>}

                      {(item.isFollowUp && item.questionType.name != "short-answer") && (
                        <div style={{ marginTop: "15px" }}>
                          <StyledInputV2
                            placeholder={"eg. What would you suggest?"}
                            name="title"
                            onChange={(e)=>{this.onFollowUpQueChange(index, e); }}
                            value={item.followUpQue}
                            // maxLength="50"
                            style={{
                              background: "rgba(247, 245, 235, 0.50)",
                              border: "1px solid rgba(0, 92, 135, 0.30)",
                              color: "#005C87",fontFamily: "Rubik-Medium",fontSize: "16px",fontWeight: 400,lineHeight: "24px"
                            }}
                          />
                        </div>
                      )} */}
                    </QueAndCard>

                    <div
                      style={{ height: "25px", background: "white" }}
                    ></div>
                  </React.Fragment>
                )):null}

                {!isActiveOrUpcoming && (
                  <div className="addex" ref={this.targetDivRef}>
                    <button onClick={this.addNewQue}>+ Add New Question</button>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  showDatePicker = (value) => {
    if (this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: "",
      });
    } else {
      this.setState({
        openDateTimePicker: value,
      });
    }
  };

  setDatePickerWrapper = (node) => {
    this.datePickerWrapper = node;
  };

  removeCommaFromFirstPlace = (str) => {
    if (str.charAt(0) === ",") {
      return str.substring(1);
    }
    return str;
  };

  inviteToggleButton = (e) => {
    this.setState({
      usersToInvite: e,
    });
  };

  onChangeLocation = (id) => {
    const { city_location } = this.state;
    if (city_location.includes(id)) {
      let index = city_location.findIndex((item) => item === id);
      if (index > -1) {
        city_location.splice(index, 1);
      }
    } else {
      city_location.push(id);
    }
    this.setState({
      city_location: city_location,
    });
  };

  onChangeDepartment = (id) => {
    const { departments } = this.state;
    if (departments.includes(id)) {
      let index = departments.findIndex((item) => item === id);
      if (index > -1) {
        departments.splice(index, 1);
      }
    } else {
      departments.push(id);
    }
    this.setState({
      departments: departments,
    });
  };

  onChangeTeam = (id) => {
    const { teams } = this.state;
    if (teams.includes(id)) {
      let index = teams.findIndex((item) => item === id);
      if (index > -1) {
        teams.splice(index, 1);
      }
    } else {
      teams.push(id);
    }
    this.setState({
      teams: teams,
    });
  };

  selectAllDepartment = (e, locationDetails) => {
    e.preventDefault();
    const { departments } = this.state;
    if (departments.length == locationDetails?.length) {
      this.setState({
        departments: [],
      });
    } else {
      let arr = [];
      for (let i = 0; i < locationDetails?.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        departments: arr,
      });
    }
  };

  selectAllTeam = (e, teamDetails)=>{
    e.preventDefault();
    const { teams } = this.state;
    if (teams.length == teamDetails.length) {
      this.setState({
        teams: [],
      });
    } else {
      let arr = [];
      for (let i = 0; i < teamDetails.length; i++) {
        arr.push(teamDetails[i].id);
      }
      this.setState({
        teams: arr,
      });
    }

  }

  selectAllLocation = (e, locationDetails) => {
    e.preventDefault();
    const { city_location } = this.state;
    if (city_location.length == locationDetails?.length) {
      this.setState({
        city_location: [],
      });
    } else {
      let arr = [];
      for (let i = 0; i < locationDetails?.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        city_location: arr,
      });
    }
  };

  selectSpouseDependent = (stateName) => {
    this.setState((prev) => ({
      [stateName]: !prev[stateName],
    }));
  };

  selectAllCompanies = (e) => {
    e.preventDefault();
    let invitedCompanies = [];
    const { allCompanies } = this.props;
    if (this.state.companies.length === allCompanies.length) {
      invitedCompanies = [];
    } else {
      allCompanies.map((company) => {
        invitedCompanies.push(company.id);
      });
    }
    this.setState({
      companies: invitedCompanies,
    });
  };

  onCheckboxChange = (element, value) => {
    const { companies } = this.state;
    if (companies.includes(element)) {
      let index = companies.findIndex((item) => item === element);
      if (index > -1) {
        companies.splice(index, 1);
      }
    } else {
      companies.push(element);
    }
    this.setState({
      companies: companies,
      selectedCompanyValue: value,
    });
  };

  onSelectLocation = (name, /*value, id*/) => {
    window.console.log("name",name)
    this.setState({
      [name]: name.company_name,
      companies: [name.id],
      isEmployee:false,
      isDependent:false,
      isSpouse:false,
      selectedCompanyValue: name.company_name,
    });
    
    this.props.getCompanyLocation(name.id);
    this.props.getCompanyDepartment(name.id);
    this.getTeams(name.id);
    this.getCompanyInfo(name.id);
  };

  companyToggleButton = (e) => {
    this.setState({
      selectedCompanyCheckBox: e,
      companies: [],
      selectedCompanyValue: "",
    });
    if (e === 0) {
      this.setState({
        city_location: [],
      });
    }
  };

  handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    this.setState({ searchTerm: value });

    const filtered = this.props.allCompanies.filter((company) =>
      company.company_name.toLowerCase().includes(value)
    );

    this.setState({ filteredCompanies: filtered });
  };

  // render teams,locations,departments listing

  renderListingsContent = () => {
    const { 
      role, 
      locationDetails, 
      departmentDetails, 
    } = this.props;

    const{
      allTeams, 
      usersToInvite, 
      selectedCompanyCheckBox, 
      selectedCompanyValue, 
      city_location, 
      departments, 
      teams 
    }=this.state
  
    // Conditions for all types
    const isAdmin = role === "ADMIN";
    const showLocations = locationDetails?.length > 0 && usersToInvite === 2;
    const showDepartments = departmentDetails?.length > 0 && usersToInvite === 1;
    const showTeams = allTeams?.length > 0 && usersToInvite === 3;
  
    // Admin and non-admin conditions
    const validAdminLocations = isAdmin && selectedCompanyCheckBox === 1 && selectedCompanyValue.length > 0;
    const validNonAdminLocations = !isAdmin;
    const validAdminDepartments = isAdmin && selectedCompanyCheckBox === 1 && selectedCompanyValue.length > 0;
    const validNonAdminDepartments = !isAdmin;
    const validAdminTeams = isAdmin && selectedCompanyCheckBox === 1 && selectedCompanyValue.length > 0;
    const validNonAdminTeams = !isAdmin;
  
    return (
      <>
        {/* Locations Section */}
        {showLocations && (validAdminLocations || validNonAdminLocations) && (
          <>
            <Heading>
              <div className="name" style={{ color: "#005c87" }}>
                {this.props.t("Select Locations")}
                <span className="astric">*</span>
              </div>
            </Heading>
  
            <Locationwrapper background="white" style={{ zIndex: "10", position: "relative" }}>
              <div className="checkBoxWidth">
                <span style={{ color: "#005c87" }}>
                  <CustomRadioButton
                    onClick={(e) => this.selectAllLocation(e, locationDetails)}
                    style={{ color: "#005c87", border: "1px solid #669db7" }}
                    background={"#85c0ea"}
                  >
                    {city_location.length === locationDetails.length && <div></div>}
                  </CustomRadioButton>{" "}
                  {city_location.length === locationDetails.length ? this.props.t("Deselect All") : this.props.t("Select All")}
                </span>
              </div>
              {locationDetails.map((loc, index) => (
                <div className="checkBoxWidth" key={index + loc.id}>
                  <span style={{ color: "#005c87" }}>
                    <CustomRadioButton
                      onClick={() => this.onChangeLocation(loc.id)}
                      background="#afcbd3"
                      style={{ border: "1px solid #649bb3" }}
                    >
                      {city_location.includes(loc.id) && (
                        <img src={ImageUrl + "/images/newRadioCheck.svg"} alt="" />
                      )}
                    </CustomRadioButton>{" "}
                    {this.removeCommaFromFirstPlace(loc.location)}
                  </span>
                </div>
              ))}
            </Locationwrapper>
          </>
        )}
  
        {/* Departments Section */}
        {showDepartments && (validAdminDepartments || validNonAdminDepartments) && (
          <>
            <Heading>
              <div className="name" style={{ color: "#005c87" }}>
                {this.props.t("Select Departments")}
                <span className="astric">*</span>
              </div>
            </Heading>
  
            <Locationwrapper background="white" style={{ zIndex: "10", position: "relative" }}>
              <div className="checkBoxWidth">
                <span style={{ color: "#005c87" }}>
                  <CustomRadioButton
                    onClick={(e) => this.selectAllDepartment(e, departmentDetails)}
                    style={{ color: "#005c87", border: "1px solid #669db7" }}
                    background={"#85c0ea"}
                  >
                    {departments.length === departmentDetails.length && <div></div>}
                  </CustomRadioButton>{" "}
                  {departments.length === departmentDetails.length ? this.props.t("Deselect All") : this.props.t("Select All")}
                </span>
              </div>
              {departmentDetails.map((dep, index) => (
                <div className="checkBoxWidth" key={index + dep.id}>
                  <span style={{ color: "#005c87" }}>
                    <CustomRadioButton
                      onClick={() => this.onChangeDepartment(dep.id)}
                      background="#afcbd3"
                      style={{ border: "1px solid #649bb3" }}
                    >
                      {departments.includes(dep.id) && (
                        <img src={ImageUrl + "/images/newRadioCheck.svg"} alt="" />
                      )}
                    </CustomRadioButton>{" "}
                    {this.removeCommaFromFirstPlace(dep.department)}
                  </span>
                </div>
              ))}
            </Locationwrapper>
          </>
        )}
  
        {/* Teams Section */}
        {showTeams && (validAdminTeams || validNonAdminTeams) && (
          <>
            <Heading>
              <div className="name" style={{ color: "#005c87" }}>
                {this.props.t("Select Teams")}
                <span className="astric">*</span>
              </div>
            </Heading>
  
            <Locationwrapper background="white" style={{ zIndex: "10", position: "relative" }}>
              <div className="checkBoxWidth">
                <span style={{ color: "#005c87" }}>
                  <CustomRadioButton
                    onClick={(e) => this.selectAllTeam(e, allTeams)}
                    style={{ color: "#005c87", border: "1px solid #669db7" }}
                    background={"#85c0ea"}
                  >
                    {teams.length === allTeams.length && <div></div>}
                  </CustomRadioButton>{" "}
                  {teams.length === allTeams.length ? this.props.t("Deselect All") : this.props.t("Select All")}
                </span>
              </div>
              {allTeams.map((team, index) => (
                <div className="checkBoxWidth" key={index + team.id}>
                  <span style={{ color: "#005c87" }}>
                    <CustomRadioButton
                      onClick={() => this.onChangeTeam(team.id)}
                      background="#afcbd3"
                      style={{ border: "1px solid #649bb3" }}
                    >
                      {teams.includes(team.id) && (
                        <img src={ImageUrl + "/images/newRadioCheck.svg"} alt="" />
                      )}
                    </CustomRadioButton>{" "}
                    {this.removeCommaFromFirstPlace(team.name)}
                  </span>
                </div>
              ))}
            </Locationwrapper>
          </>
        )}
      </>
    );
  };

  onChangeEmail = (key) => {
    const { emailOption } = this.state;
    emailOption[key] = emailOption[key] === 0 ? 1 : 0;
    this.setState({ emailOption: emailOption });
  };

  stepThreeFormData = () => {
    const {
      step,
      // isEmployee,
      isDependent,
      isSpouse,
      usersToInvite,
      allTeams,
      companyInfo,
      selectedCompanyCheckBox,
      filteredCompanies,
      companies
    } = this.state;
    const { locationDetails, t, departmentDetails,role,allCompanies } = this.props;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005c87"}>
            <div className="stepsNameHighlight">
              <StepNames stepCount={step} type="survey" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("Who would you like to invite?")}</div>
              </div>
              <div className="formBody">
                {role === "ADMIN" && (
                  <StyledLabel color="#005c87">
                    {this.props.t("Launch your survey for?")}{<span>*</span>}
                  </StyledLabel>
                )}
                {role === "ADMIN" && (
                  <div className="radioButtonDiv">
                    <span onClick={() => this.companyToggleButton(0)}>
                      <RadioButton
                        id="global"
                        handler={this.companyToggleButton}
                        value={0}
                        isChecked={selectedCompanyCheckBox === 0}
                        label={t("Global")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                    <span onClick={() => this.companyToggleButton(1)}>
                      <RadioButton
                        id="company"
                        handler={this.companyToggleButton}
                        value={1}
                        isChecked={selectedCompanyCheckBox === 1}
                        label={t("For an Individual Company")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                  </div>
                )}
                {role === "ADMIN" ? (<>

                  <Heading>
                    <div className="name" style={{color:"#005c87"}}>{this.props.t(`Select companies to invite`)} &nbsp; <span> { companies.length > 0 ? "(" + companies.length + " Companies Selected)" : ""}</span></div>
                    <SearchFilter1 style={{width:"416px",maxWidth:"416px"}}>
                      <StyledInputV2
                        type="text" placeholder={t("Search company")}
                        value={this.state.searchTerm} 
                        onChange={this.handleSearch} 
                        // onKeyDown={this.handleKeyDown}
                        style={{border:"none",outline:"none"}}
                      />
                      <SearchIcon> {SearchButtonIcon()}</SearchIcon>
                    </SearchFilter1>
                  </Heading>
                    
                  <Locationwrapper background="white">
                    {selectedCompanyCheckBox === 0 ? <div className="checkBoxWidth">
                      <span style={{color:"#005c87"}}>
                        <CustomRadioButton
                          onClick={(e) =>
                            this.selectAllCompanies(e, allCompanies)
                          }
                          style={{color:"#005c87",border:"1px solid #669db7"}}
                          background={"#85c0ea"}
                        >
                          {" "}
                          {allCompanies &&
                            companies.length === allCompanies.length && (
                            <div></div>
                          )}{" "}
                        </CustomRadioButton>{" "}
                        {this.props.t("All Companies")}
                      </span>
                    </div>: null} 
                    {filteredCompanies && filteredCompanies.length > 0
                      ? filteredCompanies.map((company, index) => (
                        <div className="checkBoxWidth" key={index}>
                          <span style={{color:"#005c87"}}>
                            <CustomRadioButton
                              onClick={() =>{
                                selectedCompanyCheckBox === 0 ? this.onCheckboxChange(company.id,company.company_name) : selectedCompanyCheckBox === 1 ? this.onSelectLocation(company) : null
                              }
                              }
                              background="#afcbd3"
                              style={{border:"1px solid #649bb3"}}
                              id={company.id + "1"}
                            >
                              {" "}
                              {companies.includes(company.id) && (
                                <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                              )}{" "}
                            </CustomRadioButton>
                            {company.company_name}
                          </span>
                        </div>
                      )): null}
                  </Locationwrapper>
                    
                    
                </>) : null}

                {role === "ADMIN" && (<Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>)}
                { (<><div className="name" style={{color:"#005c87",marginTop: "20px",marginBottom:"16px"}} >
                  <StyledLabel color="#005c87">
                    {this.props.t("Select users")}{<span>*</span>}
                  </StyledLabel>
                </div>
                <div className="radioButtonDiv" style={{zIndex:"10",position:"relative"}}>
                  <span onClick={() => this.inviteToggleButton(0)}>
                    <RadioButton
                      id="all-users"
                      handler={this.inviteToggleButton}
                      value={0}
                      isChecked={usersToInvite === 0}
                      label={t("All Users")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>
                  {(role != "ADMIN" || selectedCompanyCheckBox === 1) && departmentDetails?.length > 0 &&(<span onClick={() => this.inviteToggleButton(1)}>
                    <RadioButton
                      id="departments"
                      handler={this.inviteToggleButton}
                      value={1}
                      isChecked={usersToInvite === 1}
                      label={t("Departments")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>)}
                  { (role != "ADMIN" || selectedCompanyCheckBox === 1) && locationDetails?.length > 0 &&(<span onClick={() => this.inviteToggleButton(2)}>
                    <RadioButton
                      id="locations"
                      handler={this.inviteToggleButton}
                      value={2}
                      isChecked={usersToInvite === 2}
                      label={t("Locations")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>)}
                  { (role != "ADMIN" || selectedCompanyCheckBox === 1) && allTeams.length > 0 &&( <span onClick={() => this.inviteToggleButton(3)}>
                    <RadioButton
                      id="teams"
                      handler={this.inviteToggleButton}
                      value={3}
                      isChecked={usersToInvite === 3}
                      label={t("Teams")}
                      challengeCreate={true}
                      style={{color:"#005c87"}}
                    />
                  </span>)}
                </div></>)}
                {this.renderListingsContent()}

                {(companyInfo?.show_spouse || companyInfo?.show_dependent ||  (locationDetails?.length === 0 && usersToInvite === 2)) || (selectedCompanyCheckBox === 0 && this.props.role === "ADMIN")  ? ( <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>) : null}

                {/* Spouse Dependent */}


                {(companyInfo?.show_spouse || companyInfo?.show_dependent ||  (locationDetails?.length === 0 && usersToInvite === 2)) || (selectedCompanyCheckBox === 0 && this.props.role === "ADMIN")  ? (                 
                  <Heading>
                    <div className="name" style={{color:"#005c87"}}>{("Are you also interested in extending an invitation?")}</div>
                  </Heading>) : null}

                {  <Locationwrapper  background="white">
                  {(selectedCompanyCheckBox === 0 && (this.props.role === "ADMIN" || companyInfo?.show_spouse)) || (selectedCompanyCheckBox === 1 && companyInfo?.show_spouse) ? <div className="checkBoxWidth">
                    <span style={{color:"#005c87"}}>
                      <CustomRadioButton
                        onClick={() => this.selectSpouseDependent("isSpouse")}
                        background="#afcbd3"
                        style={{border:"1px solid #649bb3"}}
                      >
                        {" "}
                        {isSpouse && (
                          // <div></div>
                          <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                        )}{" "}
                      </CustomRadioButton>
                      {this.props.t("Spouse")}
                    </span>
                  </div>:null}
                  {(selectedCompanyCheckBox === 0 && (this.props.role === "ADMIN" || companyInfo?.show_dependent)) || (selectedCompanyCheckBox === 1 && companyInfo?.show_dependent) ?<div className="checkBoxWidth">
                    <span>
                      <CustomRadioButton
                        onClick={() =>
                          this.selectSpouseDependent("isDependent")
                        }
                      >
                        {" "}
                        {isDependent && (
                          <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                        )}{" "}
                      </CustomRadioButton>
                      {this.props.t("Dependent")}
                    </span>
                  </div>:null}
                  {/* {locationDetails?.length === 0 && usersToInvite === 2 &&  (
                    <div className="checkBoxWidth">
                      <span>
                        <CustomRadioButton
                          onClick={() =>
                            this.selectSpouseDependent("isEmployee")
                          }
                        >
                          {" "}
                          {isEmployee && (
                            <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                          )}{" "}
                        </CustomRadioButton>
                        {this.props.t("Employee")}
                      </span>{" "}
                    </div>
                  )} */}
                </Locationwrapper>}
                {/* Spouse Dependent */}
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };


  handleShowCreateSurveyPopUP = () => {
    if (this.props.history?.location?.state?.type === "editsurveylibrary") {
      this.props.history.goBack();
    } else {
      if (this.state.isActiveOrUpcoming) {
        this.updateStep(3);
      } else {
        this.setState({ showCreateSurveyPopUp: true });
      }
    }
  };

  scrollToDiv = () => {
    if (this.targetDivRef.current) {
      this.targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
    this.addNewQue();
  };

  onDragStart = (event, index) => {
    event.dataTransfer.setData("dragIndex", index);
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  onDrop = (event, dropIndex) => {
    const dragIndex = event.dataTransfer.getData("dragIndex");
    const { questionAnswers } = this.state;
    const items = [...questionAnswers];
    const draggedItem = items[dragIndex];

    // // Remove the item from the original position
    items.splice(dragIndex, 1);

    // // Insert the dragged item at the new position
    items.splice(dropIndex, 0, draggedItem);

    // questionAnswers = items;

    this.setState({ questionAnswers: items });
  };


  areAllValuesNotEmpty(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].question == "") {
        this.setState({ disable: true });
        // setDisable(true)
      } else {
        // setDisable(false)
        this.setState({ disable: false });
      }
    }
  }

  changeStep = (value) => {
    this.setState({ step: value });
  }

  launchToggleButton = (e) => {
    this.setState({
      launch: e,
      // companies: [],
      // selectedCompanyValue: "",
    });
    if (e === 0) {
      this.setState({
        date: dayjs(),
        // city_location: [],
      });
    }else if(e === 1){
      this.setState({
        date: dayjs().add(1, 'day'),
      });
    }
  };

  onChangeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if (showDateTimeInput === "date") {
      this.setState({
        date: dayjs(e),
        endDate: dayjs(e).add(2, 'day'),
        openDateTimePicker: "",
      });
    } else if (stateName.includes("ate")) {
      this.setState({
        openDateTimePicker: "",
        [stateName]: dayjs(e),
      });
    } else {
      this.setState({
        [stateName]: e,
        openDateTimePicker: stateName,
      });
    }
  };

  stepFourForm = () => {
    const {
      step,
      showDateTimeInput,
      date,
      endDate,
      launch,
      emailOption
    } = this.state;
    const { t } = this.props;
    let emailOptionKeys = Object.keys(emailOption);
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005c87"}>
            <div className="stepsNameHighlight">
              <StepNames stepCount={step} type="survey" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">{this.props.t("When would you like to launch your survey?")}</div>
              </div>
              <div className="formBody">
                <React.Fragment>
                  <StyledLabel color="#005c87">
                    {this.props.t("Launch survey")}{<span>*</span>}
                  </StyledLabel>
                  <div className="radioButtonDiv" style={{marginTop:"20px"}}>
                    <span onClick={() => this.launchToggleButton(0)}>
                      <RadioButton
                        id="global"
                        handler={this.launchToggleButton}
                        value={0}
                        isChecked={launch === 0}
                        label={t("Launch now")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                    <span onClick={() => this.launchToggleButton(1)}>
                      <RadioButton
                        id="company"
                        handler={this.launchToggleButton}
                        value={1}
                        isChecked={launch === 1}
                        label={t("Schedule for later")}
                        challengeCreate={true}
                        style={{color:"#005c87"}}
                      />
                    </span>
                  </div>
                  <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>
                </React.Fragment>
                <StyledLabelV2 justify style={{marginTop:"20px"}}>
                  <div className="name" style={{ color: "#005c87" }}>
                    {this.props.t("Survey date")}{<span>*</span>}
                  </div>
                </StyledLabelV2>

                {showDateTimeInput === "date" && (
                  <React.Fragment>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MUICalendarWrapper>
                        <DateTimePicker
                          label="Select Start Date"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          sx={{width:"416px",color:"#005C87",borderColor:"#005C87"}}
                          onChange={(value)=>{this.onChangeDate(value?.$d, "date")}}
                          value={date}
                          disablePast={true}
                          // minDate={dayjs().add(1, 'day')}
                          slots={{openPickerIcon:CalendarIcon}}
                          views={['year', 'month', 'day']}
                        />
                      </MUICalendarWrapper>
                    </LocalizationProvider>
                  </React.Fragment>
                )}
                {showDateTimeInput != "date" && (
                  <React.Fragment>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MUICalendarWrapper style={{alignItems:"center"}}>

                        <DateTimePicker
                          // label="Select Start Date"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          sx={{width:"416px",color:"#005C87",borderColor:"#005C87"}}
                          onChange={(value)=>{this.onChangeDate(value?.$d, "date")}}
                          value={date}
                          disabled={launch === 0}
                          maxDate={endDate}
                          minDate={launch === 1 ? dayjs().add(1, 'day') : dayjs()}
                          slots={{openPickerIcon:CalendarIcon}}
                          disablePast={true}
                          views={['year', 'month', 'day']}
                          slotProps={{ textField: { placeholder: 'Select start sate' } }}
                        />
                        <span style={{opacity:"0.5",width:"14px",borderBottom:"3px solid #D9D9D9",borderRadius:"6px"}}></span>
                        { (<DateTimePicker
                          // label="Select End Date"
                          sx={{width:"416px"}}
                          onChange={(value)=>{this.onChangeDate(value?.$d, "endDate")}}
                          value={endDate && endDate}
                          minDate={date && date}
                          slots={{openPickerIcon:CalendarIcon}}
                          slotProps={{ textField: { placeholder: 'Select end date' } }}
                          disablePast={true}
                          views={['year', 'month', 'day']}
                        />)}
                      </MUICalendarWrapper>
                    </LocalizationProvider>
                  </React.Fragment>
                )}

                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px 0px 0px',display:"flex"}}/>

                <StyledLabel color="#005c87">
                  {this.props.t("Select the email notification option for this survey.")}
                </StyledLabel>
                <Locationwrapper
                  border
                  background="none"
                  marginTop="0"
                  padding="0"
                >
                  {emailOptionKeys.map((item, index) => (
                    <div className="checkBoxWidth" key={index} style={{width:"unset",marginRight:"64px"}}>
                      <span style={{color:"#005c87"}}>
                        <CustomRadioButton
                          checked={emailOption[item] === 1}
                          onClick={() =>
                            this.onChangeEmail(item)
                          }
                          style={{border:"1px solid #649bb3"}}
                        >
                          {emailOption[item] === 1 && (
                            <img src={ImageUrl+"/images/newRadioCheck.svg"}></img>
                          )}
                        </CustomRadioButton>
                        {t("Survey")}
                        {item == "creation_email"
                          ? t(" Creation")
                          : item == "reminder_email"? t(" Reminder")
                            : t(" Completion")}
                      </span>
                    </div>
                  ))}
                </Locationwrapper>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  convertQuestionsAnswers(data) {

    const getLikertOrOpinionScaleAnswers = (item, ans) => {
      if (item.questionType.name === "opinion-scale") {

        const result = ans?.[0]?.points === 0
          ? opinionScaleValues
          : opinionScaleValues.map((item, index) => ({
            answer: opinionScaleValues[opinionScaleValues.length - 1 - index].points,
            points: opinionScaleValues[opinionScaleValues.length - 1 - index].points,
          }));
    
        // Update values and labels
        result.forEach((item, index) => {

          if (index === 0 || index === 10) {
            item.answer = ans[index === 0 ? 0 : 1].answer;
          } else{
            item.answer = index.toString();
          }
        });
        return result;
      }
      return ans; // Return the original answers if not likert-scale or opinion-scale
    };
  
    return {
      questions_answers: data.map((item, index) => {
        const questionType = item.questionType ? item.questionType.id : null;
        const acceptMultipleResponses =
          item.questionType && item.questionType.name === "multiple-choice"
            ? item.multipleMcqResponses
              ? 1
              : 0
            : 0;
  
        let answers = item.answers
          ? item.answers.map((answer) => ({
            answer: answer.label || answer.text,
            points: answer.points,
          }))
          : [];
  
        // Apply the Likert or Opinion Scale values if applicable
        answers = getLikertOrOpinionScaleAnswers(item, answers);
  
        return {
          question: item.question,
          question_type_id: questionType,
          accept_multiple_responses: acceptMultipleResponses,
          question_order: index + 1,
          answers: answers,
        };
      }),
    };
  }

  saveEvent = () => {
    const {
      city_location,
      title,
      date,
      description,
      imgSrc,
      endDate,
      selectedSurveyPoint,
      usersToInvite,
      departments,
      teams,
      selectedSurveyValue,
      companies,
      questionAnswers,
      emailOption,
      // selectedCompanyCheckBox
    } = this.state;


    let SurveyDetails = {
      title: title,
      description: description,
      image: imgSrc,
      start_date:dayjs(date).format("YYYY-MM-DD"),
      end_date:dayjs(endDate).format("YYYY-MM-DD"),
      points: selectedSurveyPoint,
      city_state_ids: city_location,
      is_for_spouse: this.state.isSpouse ? 1 : 0,
      is_for_dependent:this.state.isDependent ? 1 : 0,
      is_for_user_specific:0,
      participant_limit: null,
      category_id: selectedSurveyValue,
      company_ids:companies,
      end_type: "date",
      questions_answers:this.convertQuestionsAnswers(questionAnswers).questions_answers,
      time_zone:TimeZone,
      ...emailOption
    };
 
    if(usersToInvite === 0){
      SurveyDetails.department_ids = [];
      SurveyDetails.city_state_ids = [];
      SurveyDetails.team_ids = [];
      SurveyDetails.is_for_employee = 1;
    }else if(usersToInvite === 1){
      SurveyDetails.department_ids = departments;
      SurveyDetails.city_state_ids = [];
      SurveyDetails.team_ids = [];
      SurveyDetails.is_for_employee = 0;
    }else if(usersToInvite === 2){
      SurveyDetails.department_ids = [];
      SurveyDetails.city_state_ids = city_location;
      SurveyDetails.team_ids = [];
      SurveyDetails.is_for_employee = 0;
    }else if(usersToInvite === 3){
      SurveyDetails.team_ids = teams;
      SurveyDetails.department_ids = [];
      SurveyDetails.city_state_ids = [];
      SurveyDetails.is_for_employee = 0;
    }
    this.PostEvent(SurveyDetails)
  };

  PostEvent = async(payload)=>{
    this.setState({buttonstatus:true})
    try{
      const res = await fetchApi(CreateSurveyV2, "POST",payload);
      console.log("testing data",res);
      if(res?.data?.message){
        toast.error(res?.data?.message);
        this.setState({buttonstatus:false})
      }else{
        toast.success(res?.data?.[0]);
        this.props.history.push("/company/surveys");
        this.setState({buttonstatus:false})
      }
    }catch(error){
      this.setState({buttonstatus:false});
      // toast.error(error)
    }
  }

  validateQuestionAnswers = () => {

    let finalQuestionAnswers =[...this.state.questionAnswers];
    let isValid = true;
    let errors = [];
  
    finalQuestionAnswers?.forEach((item, index) => {
      const { question, answers, questionType } = item;
  
      // Validate question
      if (!question || question.trim() === "" ||  !questionType.id) {
        isValid = false;
        errors.push(`Question at index ${index} is empty.`);
      }
  
      // Validate answers
      if(question?.questionType?.name != "short-answer"){
        answers?.forEach((answer, ansIndex) => {
          // console.log("answer + index", answer, "index",index);
          const { label, points, /*text*/ } = answer;
    
          if (!label || label.trim() === "") {
            // console.log("if answer + index", answer, "index",index);
            isValid = false;
            errors.push(`Label is empty in answer ${ansIndex + 1} of question ${index + 1}.`);
          }
          if (points.toString().trim() === "") {
            isValid = false;
            errors.push(`Points are empty in answer ${ansIndex + 1} of question ${index + 1}.`);
          }
        //   if (!text || text.trim() === "") {
        //     isValid = false;
        //     errors.push(`Text is empty in answer ${ansIndex + 1} of question ${index + 1}.`);
        //   }
        });
      }
    });

    return isValid;
  };

  render() {
    const {
      step,
      title,
      imgSrc,
      description,
      loading,
      showCreateSurveyPopUp,
      createdSurveyDetails,
      showQueReorderPopUp,
      questionAnswers,
      endDate,
      selectedSurveyPoint,
      city_location,
      usersToInvite,
      // isEmployee,
      // isDependent,
      // isSpouse,
      departments,
      teams,
      selectedSurveyCategory,
      companies,
      buttonstatus
    } = this.state;
    const { /*locationDetails, companyDetails,*/ role  } = this.props;
    if (loading) {
      return <Waiting />;
    }
    return (
      <React.Fragment>
        {step === 1
          ? this.stepOneFormData()
          : step === 2
            ? this.stepTwoForm()
            : step === 3
              ? this.stepThreeFormData()
              : this.stepFourForm()}
        {step === 1 ? (
          <ButtonContainer isPosition={true}>
            <Main>
              <CommonButton
                btnType={"squareIcon"}
                onClick={() => this.updateStep(2)}
                title={this.props.t("Next")}
                styles={{color:"#007AB1"}}
                disabled={!title ||!imgSrc ||!description}
                nextIcon={NextArrows("white")}
              />
            </Main>
          </ButtonContainer>
        ) : step === 2 ? (
          <ButtonContainer isPosition={true}>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center" }}
                onClick={() => this.updateStep(1)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <CommonButton
                btnType={"squareIcon"}
                styles={{marginAuto:"auto",color:"#007AB1" }}
                onClick={() => this.updateStep(3)}
                title={this.props.t("Next")}
                nextIcon={NextArrows("white")}
                disabled={(!selectedSurveyPoint && selectedSurveyPoint!= 0)||!this.validateQuestionAnswers() || !selectedSurveyCategory || questionAnswers.length < 2}
              />
            </div>
          </ButtonContainer>
        ) : step === 3 ? (
          <ButtonContainer isPosition={true}>
            <div className="wrapper">
              <CommonButton
                btnType={"squareIcon"}
                styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center" }}
                onClick={() => this.updateStep(2)}
                title={this.props.t("Go Back")}
                icon={Arrows()}
              />
              <CommonButton
                btnType={"squareIcon"}
                styles={{marginAuto:"auto",color:"#007AB1" }}
                onClick={() => this.updateStep(4)}
                title={this.props.t("Next")}
                nextIcon={NextArrows("white")}
                disabled={ !companies.length > 0 && role === "ADMIN"|| companies.length === 0 || (usersToInvite === 2 && !city_location.length > 0) || (usersToInvite === 1 && !departments.length > 0) || (usersToInvite === 3 && !teams.length > 0)}
              />
            </div>
          </ButtonContainer>
        ) : (
          step === 4 && (
            <ButtonContainer isPosition={true}>
              <div className="wrapper">
                <CommonButton
                  btnType={"squareIcon"}
                  styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center"}}
                  onClick={() => this.updateStep(3)}
                  title={this.props.t("Go Back")}
                  icon={Arrows()}
                />
                <CommonButton
                  btnType={"squareIcon"}
                  styles={{color:"#007AB1", marginAuto:'auto'}}
                  onClick={() => this.saveEvent()}
                  title={this.props.t("Launch Survey")}
                  nextIcon={LaunchEventIcon()}
                  disabled={!endDate || buttonstatus}
                />
              </div>
            </ButtonContainer>
          )
        )}

        {showCreateSurveyPopUp && (
          <CreateSurveyPopUp
            headerText={"Survey Created"}
            showModal={showCreateSurveyPopUp}
            companyId={this.state.companyId}
            suvreyId={this.props?.history?.location?.state?.surveyId}
            updateStep={this.updateStep}
            onClose={() => {
              this.setState({ showCreateSurveyPopUp: false });
            }}
            title={createdSurveyDetails.title || ""}
          />
        )}
        {showQueReorderPopUp && (
          <QuestionReorderPopUp
            headerText={"Reorder Questions"}
            showModal={showQueReorderPopUp}
            companyId={this.state.companyId}
            suvreyId={this.props.history?.location?.state?.surveyId}
            updateStep={this.updateStep}
            onClose={() => {
              this.setState({ showQueReorderPopUp: false });
            }}
            questions={questionAnswers}
            onDragStart={this.onDragStart}
            onDragOver={this.onDragOver}
            onDrop={this.onDrop}
          />
        )}
      </React.Fragment>
    );
  }
}

LaunchSurvey.propTypes = {
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  t: PropTypes.func,
  companyInfo: PropTypes.object.isRequired,
  getCompanyDepartment: PropTypes.func,
  getCompanyLocation: PropTypes.func,
  allowChallengeEventForSpouseDependent: PropTypes.func,
  companyDetails: PropTypes.object,
  departmentDetails: PropTypes.array,
  locationDetails: PropTypes.array,
  fetchCompanyForUser: PropTypes.func,
  fetchAllCompanies: PropTypes.func.isRequired,
  allCompanies:PropTypes.array,
  userCompany: PropTypes.object,
};

const mapStateToProps = (state) => ({
  userCompany: state.profileData.userCompany,
  locationDetails: state.register.locationDetails,
  companyDetails: state.profileData.companyDetails,
  departmentDetails: state.register.departmentDetails,
  allCompanies: state.challenges.allCompanies,
});

const mapDispatchToProps = (dispatch) => ({
  allowChallengeEventForSpouseDependent: () =>dispatch(allowChallengeEventForSpouseDependent),
  fetchCompanyForUser: () => dispatch(getUserCompanyDetails()),
  getCompanyLocation: (id) => dispatch(getCompanyLocation(id)),
  getCompanyDepartment: (id) => dispatch(getCompanyDepartment(id)),
  fetchAllCompanies: () => dispatch(getAllCompanies()),
});

export default withRouter(connect(mapStateToProps , mapDispatchToProps)(withTranslation()(LaunchSurvey)));
